.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  box-sizing: border-box;
  display: inline-block;
  background-color: transparent;
  padding: 7px 20px;
  border-radius: 0;
  border: 1px solid #5b6069;
  color: #5b6069;
  font-size: 17px;
  line-height: 1.41176471;
  letter-spacing: 0.03em;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), border 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), color 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #5b6069;
}
.button:active {
  color: #fff;
  background-color: #5b6069;
}
.button-invers {
  box-sizing: border-box;
  display: inline-block;
  padding: 7px 20px;
  border-radius: 0;
  border: 1px solid #5b6069;
  color: #5b6069;
  font-size: 17px;
  line-height: 1.41176471;
  letter-spacing: 0.03em;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), border 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), color 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.button-invers:hover,
.button-invers:focus {
  color: #fff;
  background-color: #5b6069;
}
.button-invers:active {
  color: #fff;
  background-color: #5b6069;
}
.button-invers:hover,
.button-invers:focus {
  color: #5b6069;
  background-color: #fff;
}
.button-invers:active {
  color: #5b6069;
  background-color: #fff;
}
#contact {
  display: block;
  float: right;
  margin-top: 30px;
  font-size: 16px;
  line-height: 1.5;
  text-align: right;
}
#contact .meta {
  margin: 0 15px;
}
#contact .meta:last-child {
  margin-right: 0;
}
#contact .meta:hover,
#contact .meta:focus {
  color: #aab0b7;
}
.navbar div.navi {
  float: left;
}
.navbar div.navi > .item {
  float: left;
}
.navbar div.navi > .item > .menu {
  display: block;
  font-size: 21px;
  line-height: 1.23809524;
  font-weight: 400;
  letter-spacing: 0.04em;
}
.navbar div.navi > .item > .menu:hover,
.navbar div.navi > .item > .menu:focus,
.navbar div.navi > .item > .menu.path {
  color: #aab0b7;
}
@media (min-width: 1200px) {
  .navbar .togglenavigation,
  .navbar .mobile-navigation {
    display: none;
  }
}
.navbar div.sub1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  width: 100%;
  margin-top: 60px;
  transition: margin 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-scroll-triggered--active .navbar div.sub1 {
  margin-top: 20px;
}
.navbar div.sub1 > .item {
  position: relative;
  margin: 0 20px;
}
.navbar div.sub1 > .item.init {
  margin-left: 0;
}
.navbar div.sub1 > .item.exit {
  margin-right: 0;
}
.navbar div.sub1 > .item > .menu {
  padding: 0 0 20px;
}
.navbar div.sub2 {
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 200;
  min-width: 200px;
  background-color: #fff;
}
.navbar div.sub2 > .item {
  overflow: hidden;
  max-height: 0;
  width: 100%;
  transition: max-height 250ms ease;
}
.navbar div.sub2 > .item > .menu {
  margin: 0 20px;
  padding: 3px 0;
  white-space: nowrap;
}
.navbar div.sub2 > .item.init > .menu {
  margin-top: 10px;
}
.navbar div.sub2 > .item.exit > .menu {
  margin-bottom: 25px;
}
@media only screen and (max-width: 1800px) {
  .navbar div.sub1 > .item:nth-last-child(2) div.sub2,
  .navbar div.sub1 > .item:last-child div.sub2 {
    left: auto;
    right: 0;
  }
}
.navbar div.sub1 > .item:hover div.sub2 > .item {
  max-height: 150px;
  transition: max-height 700ms ease;
}
#home {
  width: 295px;
  height: 97px;
  margin-top: 30px;
  margin-bottom: 0;
}
.cb-scroll-triggered--active #home {
  margin-top: 10px;
  height: 56px;
  margin-bottom: 0;
}
.desk {
  max-width: calc(100% - 180px);
}
.desk.desk--large {
  max-width: 100%;
}
#head {
  margin-top: 80px;
}
.section--header {
  margin-top: 233px;
}
.cb-scroll-triggered--active .section--header {
  margin-top: 132px;
}
.section--footer #social {
  width: 20%;
  margin-left: auto;
  margin-top: 0;
}
.section--footer #services {
  width: 46%;
}
.section--footer div.navi.sub1 {
  columns: 3 180px;
}
.area.base {
  margin-top: 34px;
  margin-bottom: 34px;
}
.area.base .unit {
  width: calc(100% / 3 - 12px);
  padding: 30px 50px;
}
#view .base .unit .part.link a.open {
  padding-left: 70px;
  background-position: left 50px bottom 34px;
}
.area.farnorth {
  margin-top: 40px;
  margin-bottom: 40px;
}
.area.farnorth .unit {
  width: 50%;
  padding: 60px 50px;
}
.area.farnorth .unit.seam .body .part.link:last-child,
.area.farnorth .unit.flat .body .part.link:last-child,
.area.farnorth .unit.edge .body .part.link:last-child {
  padding-top: 20px;
}
.container--north {
  margin-top: 40px;
}
.area.north {
  margin-top: 40px;
  margin-bottom: 40px;
}
.cb-layout1 .container--main {
  margin-top: 40px;
}
.cb-layout1 .container--main .left {
  width: 50%;
  margin-left: 8.33333333%;
}
.cb-layout1 .container--main .right {
  width: 41.66666667%;
}
.cb-layout1 .area.farwest,
.cb-layout1 .area.fareast,
.cb-layout1 .area.north,
.cb-layout2 .area.east {
  width: calc(100% + 40px);
  margin-left: -20px;
}
.cb-layout1 .area.farwest .unit.slim,
.cb-layout1 .area.fareast .unit.slim,
.cb-layout1 .area.north .unit.slim,
.cb-layout2 .area.east .unit.slim {
  width: 50%;
}
.cb-layout1 .area.farwest .unit .part,
.cb-layout1 .area.fareast .unit .part,
.cb-layout1 .area.north .unit .part,
.cb-layout2 .area.east .unit .part,
.cb-layout1 .area.farwest .unit .foot,
.cb-layout1 .area.fareast .unit .foot,
.cb-layout1 .area.north .unit .foot,
.cb-layout2 .area.east .unit .foot {
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}
.cb-layout1 .area.farwest .unit .part.tiny,
.cb-layout1 .area.fareast .unit .part.tiny,
.cb-layout1 .area.north .unit .part.tiny,
.cb-layout2 .area.east .unit .part.tiny,
.cb-layout1 .area.farwest .unit .foot.tiny,
.cb-layout1 .area.fareast .unit .foot.tiny,
.cb-layout1 .area.north .unit .foot.tiny,
.cb-layout2 .area.east .unit .foot.tiny {
  width: calc(50% - 40px);
}
.cb-layout1 .area.farwest .unit.pure.wide,
.cb-layout1 .area.fareast .unit.pure.wide,
.cb-layout1 .area.north .unit.pure.wide,
.cb-layout2 .area.east .unit.pure.wide {
  padding: 0 90px;
}
.cb-layout1 .area.farwest .unit.seam.slim,
.cb-layout1 .area.fareast .unit.seam.slim,
.cb-layout1 .area.north .unit.seam.slim,
.cb-layout2 .area.east .unit.seam.slim {
  padding-right: 90px;
}
.cb-layout1 .area.farwest .unit.flat.slim,
.cb-layout1 .area.fareast .unit.flat.slim,
.cb-layout1 .area.north .unit.flat.slim,
.cb-layout2 .area.east .unit.flat.slim {
  padding-left: 90px;
}
.cb-layout2 .area.main,
.cb-layout3 .area.main,
.cb-layout2 .area.farwest,
.cb-layout2 .fareast,
.cb-layout2 .area.north {
  width: calc(100% + 40px);
  margin-left: -20px;
}
.cb-layout2 .area.main .unit .part,
.cb-layout3 .area.main .unit .part,
.cb-layout2 .area.farwest .unit .part,
.cb-layout2 .fareast .unit .part,
.cb-layout2 .area.north .unit .part,
.cb-layout2 .area.main .unit .foot,
.cb-layout3 .area.main .unit .foot,
.cb-layout2 .area.farwest .unit .foot,
.cb-layout2 .fareast .unit .foot,
.cb-layout2 .area.north .unit .foot {
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}
.cb-layout2 .area.main .unit .part.tiny,
.cb-layout3 .area.main .unit .part.tiny,
.cb-layout2 .area.farwest .unit .part.tiny,
.cb-layout2 .fareast .unit .part.tiny,
.cb-layout2 .area.north .unit .part.tiny,
.cb-layout2 .area.main .unit .foot.tiny,
.cb-layout3 .area.main .unit .foot.tiny,
.cb-layout2 .area.farwest .unit .foot.tiny,
.cb-layout2 .fareast .unit .foot.tiny,
.cb-layout2 .area.north .unit .foot.tiny {
  width: calc(50% - 40px);
}
.cb-layout2 .area.main .unit.seam,
.cb-layout3 .area.main .unit.seam,
.cb-layout2 .area.farwest .unit.seam,
.cb-layout2 .fareast .unit.seam,
.cb-layout2 .area.north .unit.seam,
.cb-layout2 .area.main .unit.flat,
.cb-layout3 .area.main .unit.flat,
.cb-layout2 .area.farwest .unit.flat,
.cb-layout2 .fareast .unit.flat,
.cb-layout2 .area.north .unit.flat,
.cb-layout2 .area.main .unit.edge,
.cb-layout3 .area.main .unit.edge,
.cb-layout2 .area.farwest .unit.edge,
.cb-layout2 .fareast .unit.edge,
.cb-layout2 .area.north .unit.edge {
  margin-top: 40px;
  margin-bottom: 40px;
}
.cb-layout2 .area.main .unit.seam .part,
.cb-layout3 .area.main .unit.seam .part,
.cb-layout2 .area.farwest .unit.seam .part,
.cb-layout2 .fareast .unit.seam .part,
.cb-layout2 .area.north .unit.seam .part,
.cb-layout2 .area.main .unit.flat .part,
.cb-layout3 .area.main .unit.flat .part,
.cb-layout2 .area.farwest .unit.flat .part,
.cb-layout2 .fareast .unit.flat .part,
.cb-layout2 .area.north .unit.flat .part,
.cb-layout2 .area.main .unit.edge .part,
.cb-layout3 .area.main .unit.edge .part,
.cb-layout2 .area.farwest .unit.edge .part,
.cb-layout2 .fareast .unit.edge .part,
.cb-layout2 .area.north .unit.edge .part {
  width: calc(100% - 100px);
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 40px;
}
.cb-layout2 .area.main .unit.seam .part.pict,
.cb-layout3 .area.main .unit.seam .part.pict,
.cb-layout2 .area.farwest .unit.seam .part.pict,
.cb-layout2 .fareast .unit.seam .part.pict,
.cb-layout2 .area.north .unit.seam .part.pict,
.cb-layout2 .area.main .unit.flat .part.pict,
.cb-layout3 .area.main .unit.flat .part.pict,
.cb-layout2 .area.farwest .unit.flat .part.pict,
.cb-layout2 .fareast .unit.flat .part.pict,
.cb-layout2 .area.north .unit.flat .part.pict,
.cb-layout2 .area.main .unit.edge .part.pict,
.cb-layout3 .area.main .unit.edge .part.pict,
.cb-layout2 .area.farwest .unit.edge .part.pict,
.cb-layout2 .fareast .unit.edge .part.pict,
.cb-layout2 .area.north .unit.edge .part.pict {
  width: 100%;
  margin: 0;
}
.cb-layout2 .area.main .unit.seam .part.tiny,
.cb-layout3 .area.main .unit.seam .part.tiny,
.cb-layout2 .area.farwest .unit.seam .part.tiny,
.cb-layout2 .fareast .unit.seam .part.tiny,
.cb-layout2 .area.north .unit.seam .part.tiny,
.cb-layout2 .area.main .unit.flat .part.tiny,
.cb-layout3 .area.main .unit.flat .part.tiny,
.cb-layout2 .area.farwest .unit.flat .part.tiny,
.cb-layout2 .fareast .unit.flat .part.tiny,
.cb-layout2 .area.north .unit.flat .part.tiny,
.cb-layout2 .area.main .unit.edge .part.tiny,
.cb-layout3 .area.main .unit.edge .part.tiny,
.cb-layout2 .area.farwest .unit.edge .part.tiny,
.cb-layout2 .fareast .unit.edge .part.tiny,
.cb-layout2 .area.north .unit.edge .part.tiny {
  width: calc(50% - 100px);
}
.cb-layout2 .area.main .unit.seam .part.tiny.pict,
.cb-layout3 .area.main .unit.seam .part.tiny.pict,
.cb-layout2 .area.farwest .unit.seam .part.tiny.pict,
.cb-layout2 .fareast .unit.seam .part.tiny.pict,
.cb-layout2 .area.north .unit.seam .part.tiny.pict,
.cb-layout2 .area.main .unit.flat .part.tiny.pict,
.cb-layout3 .area.main .unit.flat .part.tiny.pict,
.cb-layout2 .area.farwest .unit.flat .part.tiny.pict,
.cb-layout2 .fareast .unit.flat .part.tiny.pict,
.cb-layout2 .area.north .unit.flat .part.tiny.pict,
.cb-layout2 .area.main .unit.edge .part.tiny.pict,
.cb-layout3 .area.main .unit.edge .part.tiny.pict,
.cb-layout2 .area.farwest .unit.edge .part.tiny.pict,
.cb-layout2 .fareast .unit.edge .part.tiny.pict,
.cb-layout2 .area.north .unit.edge .part.tiny.pict {
  width: 50%;
  margin: 0;
}
@media (max-width: 1199px) {
  .cb-layout2 .area.main .unit.seam .part,
  .cb-layout3 .area.main .unit.seam .part,
  .cb-layout2 .area.farwest .unit.seam .part,
  .cb-layout2 .fareast .unit.seam .part,
  .cb-layout2 .area.north .unit.seam .part,
  .cb-layout2 .area.main .unit.flat .part,
  .cb-layout3 .area.main .unit.flat .part,
  .cb-layout2 .area.farwest .unit.flat .part,
  .cb-layout2 .fareast .unit.flat .part,
  .cb-layout2 .area.north .unit.flat .part,
  .cb-layout2 .area.main .unit.edge .part,
  .cb-layout3 .area.main .unit.edge .part,
  .cb-layout2 .area.farwest .unit.edge .part,
  .cb-layout2 .fareast .unit.edge .part,
  .cb-layout2 .area.north .unit.edge .part {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .cb-layout2 .area.main .unit.seam .part,
  .cb-layout3 .area.main .unit.seam .part,
  .cb-layout2 .area.farwest .unit.seam .part,
  .cb-layout2 .fareast .unit.seam .part,
  .cb-layout2 .area.north .unit.seam .part,
  .cb-layout2 .area.main .unit.flat .part,
  .cb-layout3 .area.main .unit.flat .part,
  .cb-layout2 .area.farwest .unit.flat .part,
  .cb-layout2 .fareast .unit.flat .part,
  .cb-layout2 .area.north .unit.flat .part,
  .cb-layout2 .area.main .unit.edge .part,
  .cb-layout3 .area.main .unit.edge .part,
  .cb-layout2 .area.farwest .unit.edge .part,
  .cb-layout2 .fareast .unit.edge .part,
  .cb-layout2 .area.north .unit.edge .part {
    margin-bottom: 20px;
  }
}
.cb-layout2 .area.main .unit.seam .part:first-child:not(.pict),
.cb-layout3 .area.main .unit.seam .part:first-child:not(.pict),
.cb-layout2 .area.farwest .unit.seam .part:first-child:not(.pict),
.cb-layout2 .fareast .unit.seam .part:first-child:not(.pict),
.cb-layout2 .area.north .unit.seam .part:first-child:not(.pict),
.cb-layout2 .area.main .unit.flat .part:first-child:not(.pict),
.cb-layout3 .area.main .unit.flat .part:first-child:not(.pict),
.cb-layout2 .area.farwest .unit.flat .part:first-child:not(.pict),
.cb-layout2 .fareast .unit.flat .part:first-child:not(.pict),
.cb-layout2 .area.north .unit.flat .part:first-child:not(.pict),
.cb-layout2 .area.main .unit.edge .part:first-child:not(.pict),
.cb-layout3 .area.main .unit.edge .part:first-child:not(.pict),
.cb-layout2 .area.farwest .unit.edge .part:first-child:not(.pict),
.cb-layout2 .fareast .unit.edge .part:first-child:not(.pict),
.cb-layout2 .area.north .unit.edge .part:first-child:not(.pict) {
  margin-top: 40px;
}
@media (max-width: 1199px) {
  .cb-layout2 .area.main .unit.seam .part:first-child:not(.pict),
  .cb-layout3 .area.main .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .area.farwest .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .fareast .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .area.north .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .area.main .unit.flat .part:first-child:not(.pict),
  .cb-layout3 .area.main .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .area.farwest .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .fareast .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .area.north .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .area.main .unit.edge .part:first-child:not(.pict),
  .cb-layout3 .area.main .unit.edge .part:first-child:not(.pict),
  .cb-layout2 .area.farwest .unit.edge .part:first-child:not(.pict),
  .cb-layout2 .fareast .unit.edge .part:first-child:not(.pict),
  .cb-layout2 .area.north .unit.edge .part:first-child:not(.pict) {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .cb-layout2 .area.main .unit.seam .part:first-child:not(.pict),
  .cb-layout3 .area.main .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .area.farwest .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .fareast .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .area.north .unit.seam .part:first-child:not(.pict),
  .cb-layout2 .area.main .unit.flat .part:first-child:not(.pict),
  .cb-layout3 .area.main .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .area.farwest .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .fareast .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .area.north .unit.flat .part:first-child:not(.pict),
  .cb-layout2 .area.main .unit.edge .part:first-child:not(.pict),
  .cb-layout3 .area.main .unit.edge .part:first-child:not(.pict),
  .cb-layout2 .area.farwest .unit.edge .part:first-child:not(.pict),
  .cb-layout2 .fareast .unit.edge .part:first-child:not(.pict),
  .cb-layout2 .area.north .unit.edge .part:first-child:not(.pict) {
    margin-top: 20px;
  }
}
.cb-layout2 .area.main .unit.seam .part.pict + .part,
.cb-layout3 .area.main .unit.seam .part.pict + .part,
.cb-layout2 .area.farwest .unit.seam .part.pict + .part,
.cb-layout2 .fareast .unit.seam .part.pict + .part,
.cb-layout2 .area.north .unit.seam .part.pict + .part,
.cb-layout2 .area.main .unit.flat .part.pict + .part,
.cb-layout3 .area.main .unit.flat .part.pict + .part,
.cb-layout2 .area.farwest .unit.flat .part.pict + .part,
.cb-layout2 .fareast .unit.flat .part.pict + .part,
.cb-layout2 .area.north .unit.flat .part.pict + .part,
.cb-layout2 .area.main .unit.edge .part.pict + .part,
.cb-layout3 .area.main .unit.edge .part.pict + .part,
.cb-layout2 .area.farwest .unit.edge .part.pict + .part,
.cb-layout2 .fareast .unit.edge .part.pict + .part,
.cb-layout2 .area.north .unit.edge .part.pict + .part {
  margin-top: 40px;
}
@media (max-width: 1199px) {
  .cb-layout2 .area.main .unit.seam .part.pict + .part,
  .cb-layout3 .area.main .unit.seam .part.pict + .part,
  .cb-layout2 .area.farwest .unit.seam .part.pict + .part,
  .cb-layout2 .fareast .unit.seam .part.pict + .part,
  .cb-layout2 .area.north .unit.seam .part.pict + .part,
  .cb-layout2 .area.main .unit.flat .part.pict + .part,
  .cb-layout3 .area.main .unit.flat .part.pict + .part,
  .cb-layout2 .area.farwest .unit.flat .part.pict + .part,
  .cb-layout2 .fareast .unit.flat .part.pict + .part,
  .cb-layout2 .area.north .unit.flat .part.pict + .part,
  .cb-layout2 .area.main .unit.edge .part.pict + .part,
  .cb-layout3 .area.main .unit.edge .part.pict + .part,
  .cb-layout2 .area.farwest .unit.edge .part.pict + .part,
  .cb-layout2 .fareast .unit.edge .part.pict + .part,
  .cb-layout2 .area.north .unit.edge .part.pict + .part {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .cb-layout2 .area.main .unit.seam .part.pict + .part,
  .cb-layout3 .area.main .unit.seam .part.pict + .part,
  .cb-layout2 .area.farwest .unit.seam .part.pict + .part,
  .cb-layout2 .fareast .unit.seam .part.pict + .part,
  .cb-layout2 .area.north .unit.seam .part.pict + .part,
  .cb-layout2 .area.main .unit.flat .part.pict + .part,
  .cb-layout3 .area.main .unit.flat .part.pict + .part,
  .cb-layout2 .area.farwest .unit.flat .part.pict + .part,
  .cb-layout2 .fareast .unit.flat .part.pict + .part,
  .cb-layout2 .area.north .unit.flat .part.pict + .part,
  .cb-layout2 .area.main .unit.edge .part.pict + .part,
  .cb-layout3 .area.main .unit.edge .part.pict + .part,
  .cb-layout2 .area.farwest .unit.edge .part.pict + .part,
  .cb-layout2 .fareast .unit.edge .part.pict + .part,
  .cb-layout2 .area.north .unit.edge .part.pict + .part {
    margin-top: 20px;
  }
}
.cb-layout2 .area.main .unit.seam .part:not(.pict) + .part:not(.pict),
.cb-layout3 .area.main .unit.seam .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.farwest .unit.seam .part:not(.pict) + .part:not(.pict),
.cb-layout2 .fareast .unit.seam .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.north .unit.seam .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.main .unit.flat .part:not(.pict) + .part:not(.pict),
.cb-layout3 .area.main .unit.flat .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.farwest .unit.flat .part:not(.pict) + .part:not(.pict),
.cb-layout2 .fareast .unit.flat .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.north .unit.flat .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.main .unit.edge .part:not(.pict) + .part:not(.pict),
.cb-layout3 .area.main .unit.edge .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.farwest .unit.edge .part:not(.pict) + .part:not(.pict),
.cb-layout2 .fareast .unit.edge .part:not(.pict) + .part:not(.pict),
.cb-layout2 .area.north .unit.edge .part:not(.pict) + .part:not(.pict) {
  margin-top: 0;
}
.cb-layout2 .area.main .unit.form.wide .part,
.cb-layout3 .area.main .unit.form.wide .part,
.cb-layout2 .area.farwest .unit.form.wide .part,
.cb-layout2 .fareast .unit.form.wide .part,
.cb-layout2 .area.north .unit.form.wide .part,
.cb-layout2 .area.main .unit.form.wide .foot,
.cb-layout3 .area.main .unit.form.wide .foot,
.cb-layout2 .area.farwest .unit.form.wide .foot,
.cb-layout2 .fareast .unit.form.wide .foot,
.cb-layout2 .area.north .unit.form.wide .foot {
  width: calc(100% - 40px);
  max-width: 772px;
  margin-left: 20px;
  margin-right: 20px;
}
/*# sourceMappingURL=./screen-large.css.map */